import logo from "./logo.svg";
import { nanoid } from "nanoid";
import React, { useEffect, useState } from "react";
import "./App.css";

function App() {
  const [list, setList] = useState(null);

  async function wheater() {
    const options = {
      method: "GET",
      headers: {
        "X-RapidAPI-Host": "community-open-weather-map.p.rapidapi.com",
        "X-RapidAPI-Key": "f9f00a47fcmshbbeef2135ab9a84p155e89jsn9897f9062669",
      },
    };

    const response = await fetch(
      "https://community-open-weather-map.p.rapidapi.com/forecast?q=barkarby%2C%20se",
      options
    )
      .then((response) => response.json())
      .then((response) => setList(response))
      .catch((err) => console.error(err));
  }

  useEffect(() => {
    wheater();
  }, []);

  if (list === null || list === undefined) {
    return <h1>Loading...</h1>;
  }

  console.log(list);

  return (
    <div className="App">
      <header className="App-header">
        <div className="Header-div">
          {" "}
          <h1 className="Header-div">Weather in Barkarby</h1>
          <h2 className="Header-div">5 Days / 3 Hour Forecast</h2>
        </div>
        <div>
          {list.list.map((day) => (
            <div key={nanoid()} className="Div-class">
              <p>{day.dt_txt.substring(0, 16)}</p>
              <p>Tempratur: {Math.floor(day.main.temp - 273.15)}°</p>
              <p>Feels like: {Math.floor(day.main.feels_like - 273.15)}°</p>
              <p>
                Description:{" "}
                {day.weather[0].description.charAt(0).toUpperCase() +
                  day.weather[0].description.slice(1)}
              </p>
              <p>Wind {day.wind.speed} m/s</p>
              <p>Clouds {day.clouds.all} %</p>
              <img
                src={`https://openweathermap.org/img/wn/${day.weather[0].icon}@2x.png`}
                alt="img of weather"
              />
            </div>
          ))}
        </div>
        <h6>
          Data provided by <a href="https://openweathermap.org/">OpenWeather</a>
        </h6>
      </header>
    </div>
  );
}

export default App;
